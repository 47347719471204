import React from 'react';
import Chatbox from './Chatbox'; // Update the import path

const Chatbot = () => {
  return (
    <div className="container">
      <div className="chatbox">
        <Chatbox />
      </div>
    </div>
  );
};

export default Chatbot;


